<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="models"
              backText="all models"
              title="model galleries"
              sub-title="more images from you"
              :login="login"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main class="pb-5">
      <list class="wa__large mt-9">
        <div class="gallery__box">
          <div class="wa__drop__image">
            <dropzone :model-id="modelId" @success="addImageToList" />
          </div>
          <div class="my-4 d-flex justify-left wa__gallery items">
            <div
              v-for="image in images"
              :key="image.id"
              class="pr-sm-3 mt-3 mt-sm-0 item"
            >
              <GalleryImage
                :image="image"
                :images="images"
                :role="role"
                :model-id="modelId"
              />
            </div>
          </div>
          <div class="mt-6" v-if="role === 'manager'">
            <SectionTitle title="PDF Imagaes" />
            <div class="d-flex justify-left items mt-4">
              <div
                v-for="image in pdfImages"
                :key="image.id"
                class="pr-sm-3 mt-3 mt-sm-0 item"
              >
                <GalleryImage :image="image" :images="pdfImages" />
              </div>
            </div>
          </div>
          <div
            class="py-6 d-flex justify-space-between align-items-center w-100"
            v-if="role === 'manager'"
          >
            <block-button
              text="download all images"
              height="30"
              @click.native="getLinkDownloadImages"
              class="btn__size__18 site__button width-auto float-left pa-4"
            />
            <a :href="`${$baseUrl}/print/${modelData.unique_id}`" target="_blank">
              <block-button
                text="download pdf"
                height="30"
                class="btn__size__18 site__button width-auto float-left pa-4"
              />
            </a>
          </div>
        </div>
      </list>
    </main>
    <!-- --------------------------------------------------------------------
                            Modals :: BEGIN
        ---------------------------------------------------------------------->
    <template>
      <div class="text-center">
        <AddToPdfModal
          @success="addImageToPdf"
          v-if="isModal('modal_crop_gallery_image_for_pdf')"
        />
      </div>
    </template>
    <!-- --------------------------------------------------------------------
                                    Modals :: END
        ---------------------------------------------------------------------->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModelRepository from "@/abstraction/repository/modelRepository";
import Dropzone from "@/components/Global/Input/Dropzone";
import GalleryImage from "@/components/Global/Section/GalleryImage";
import { UserStorage } from "@/utils/storage";
import SectionTitle from "@/components/Global/Section/SectionTitle.vue";
import AddToPdfModal from "./Modal/AddToPdf.vue";
let modelRepository = new ModelRepository();
export default {
  components: {
    Dropzone,
    GalleryImage,
    SectionTitle,
    AddToPdfModal,
  },
  data() {
    return {
      role: UserStorage.get() ? UserStorage.get().type : "client",
      login: true,
      modelId: null,
      images: [],
      modelData: {},
      pdfImages: [],
    };
  },
  computed: {
    ...mapGetters("modal", ["isModal"]),
  },
  methods: {
    async loadMyImages() {
      this.modelData = await modelRepository.show(this.$route.params.id);
      this.images = this.modelData.model.galleries;
      this.modelId = this.modelData.model.id;
      this.pdfImages = this.modelData.model.media.filter(
        (img) => img.collection_name === "pdf_images"
      );
    },
    addImageToList(file) {
      file["image"] = file.url;
      file["thumbnail"] = file.url;
      this.images.push(file);
    },
    addImageToPdf(file) {
      file["image"] = file.url;
      file["thumbnail"] = file.url;
      this.pdfImages.push(file);
    },
    async getLinkDownloadImages() {
      const { link } = await modelRepository.getLinkDownloadImages(this.modelData.id);
      window.open(link);
    },
  },
  async created() {
    await this.loadMyImages();
  },
};
</script>
<style scoped>
.wa__drop__image {
  background-color: var(--color-bg-gray);
  position: relative;
  padding: 80px;
  border: 1px dashed var(--color-gray);
  border-radius: 6px;
}
.wa__drop__image--text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wa__drop__image--text div:first-child {
  font-family: "Montserrat";
  font-size: 25px;
  letter-spacing: 7px;
  font-size: 25px;
  text-transform: uppercase;
}
.wa__drop__image--text div:last-child {
  font-family: "Montserrat-regular";
  text-align: center;
  letter-spacing: 7px;
  letter-spacing: 0;
  color: var(--color-gray);
}
.gallery__box {
  padding: 30px 20px;
}
.wa__gallery col {
  max-width: 14.666667% !important;
}
.wa__gallery__image {
  border-radius: 5px;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  background-position: center;
  background-size: cover;
  object-fit: cover;
}
.wa__gallery__image__remove {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.btn-remove {
  padding: 0 25px !important;
  opacity: 0.67;
  font-size: 14px;
}

.items {
  flex-wrap: wrap;
}
.items .item {
  width: 16.6666666667%;
  margin-bottom: 20px;
}
.items .item:nth-child(6n) {
  padding: 0 !important;
}
@media (max-width: 1200px) {
  .items .item {
    width: 20%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(5n) {
    padding: 0 !important;
  }
  .items .item:nth-child(6n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 960px) {
  .items .item {
    width: 25%;
    margin-bottom: 20px;
  }

  .items .item:nth-child(4n) {
    padding: 0 !important;
  }
  .items .item:nth-child(5n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 768px) {
  .items .item {
    width: 33.33333%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(3n) {
    padding: 0 !important;
  }
  .items .item:nth-child(4n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 480px) {
  .items {
    justify-content: center;
  }
  .items .item {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 !important;
  }
  .items .item:nth-child(3n),
  .items .item:nth-child(4n),
  .items .item:nth-child(5n),
  .items .item:nth-child(6n) {
    padding-right: 0 !important;
  }
}
</style>
